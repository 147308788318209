<template>
  <div v-bind:key="refreshKey">

    <b-table responsive
             v-bind:items="affiliatesData"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:busy="loading"
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             no-footer-sorting

             foot-clone
             foot-row-variant="success"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #cell(countries)="data">
        <div v-if="data.value && Object.keys(data.value).length > 0">
          <p v-for="(count, country) in data.value" :key="country" class="font-weight-bold m-0">
            {{ countries[country] }}: {{ count }}
          </p>
        </div>
        <div v-else>
          <p>____</p>
        </div>
      </template>
      <template #cell(netDeposits)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }"> {{ convertToEURO(data.value) }}</span>
      </template>

      <template v-slot:foot(company)="data">
       <span style="font-size: 15px;font-weight: bold">{{ $t('columns.total') }}</span>
      </template>
      <template v-slot:foot(ftds)="data">
        <span style="font-size: 15px;font-weight: bold">{{ totalFtds }}</span></template>
      <template v-slot:foot(leads)="data">
        <span style="font-size: 15px;font-weight: bold">{{ totalLeads }}</span></template>
      <template v-slot:foot(netDeposits)="data">
        <b-badge :variant="totalNetDeposits > 0 ? 'success' : 'danger'"  :style="{fontSize:'15px',fontWeight:'bold' }"> {{ convertToEURO(totalNetDeposits) }}</b-badge>
      </template>

      <template v-slot:foot(countries)="data">
        <div>
          <!-- Show button -->
          <b-button id="show-popover" variant="primary" size="sm" @click="togglePopover">
            Show
          </b-button>

          <!-- Popover that appears when Show button is clicked -->
          <b-popover :show.sync="showPopover"
                     target="show-popover"
                     placement="top"
                     title="Country Totals"
                     triggers="manual">
            <div v-if="totalByCountry && Object.keys(totalByCountry).length > 0">
              <p v-for="(count, country) in totalByCountry" :key="country" class="font-weight-bold">
                {{ countries[country] }}: {{ count }}
              </p>
            </div>
            <div v-else>
              <p>0</p>
            </div>
          </b-popover>
        </div>
      </template>

    </b-table>

    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.affiliates') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientClientStatusModal from '@/components/modal/clientStatusModal.vue';
import { vue } from '@/main';

export default {
  name: 'AffiliateDashboardTable',
  components: {
    ClientClientStatusModal,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    affiliatesData: [],
    totalNetDeposits:0.0,
    totalLeads:0,
    totalFtds:0,
    totalByCountry:{},
    showPopover: false
  }),
  props: {

    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 25);
    this.refresh();

  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),
    countries: {
      get() {
        // Retrieve the countryList using your localization method
        const countryList = this.$t('countryList');

        // Reduce the list to an object with code as the key and name as the value
        return countryList.reduce((acc, country) => {
          acc[country.code] = country.name;
          return acc;
        }, {});
      }
    },
    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        let label = column.key === 'clientId' ? $this.$t('columns.client') : $this.$t(`columns.${column.key}`);
        return {
          key: column.key,
          label: label,
          sortable: true,
          active: column.enable,
        };
      });

      fields = fields.filter(field => !field.key.startsWith('id') && field.key !== 'statuses');
      return fields.filter(f => f.active === true);
    },

  },
  methods: {
    ...mapActions('dashboard', ['getAffiliateDashboard']),
    togglePopover() {
      this.showPopover = !this.showPopover;

      if (this.showPopover) {
        document.addEventListener('click', this.handleOutsideClick);
      } else {
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    handleOutsideClick(event) {
      const popoverElement = document.getElementById('show-popover');
      if (popoverElement && !popoverElement.contains(event.target)) {
        this.showPopover = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'creationDate';
        sort.direction = 'ASC';
      }

      const result = await this.getAffiliateDashboard(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.affiliates') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.affiliatesData = result.data;
      this.totalNetDeposits = this.affiliatesData.reduce((total, item) => total + item['netDeposits'], 0);
      this.totalLeads = this.affiliatesData.reduce((total, item) => total + item['leads'], 0);
      this.totalFtds = this.affiliatesData.reduce((total, item) => total + item['ftds'], 0);
      this.totalByCountry =this.combineCountryTotals(this.affiliatesData);
      this.setMetaDataFromResult(result);
      this.$emit('calculate', this.affiliatesData);
      this.setLoaded(loadingToken);
    },
    combineCountryTotals(affiliatesData) {
      const combinedMap = {};

      // Iterate over each item in the items array
      affiliatesData.forEach(item => {
        Object.entries(item.countries).forEach(([country, count]) => {
          // If the country already exists in the combined map, add to its count
          if (combinedMap[country]) {
            combinedMap[country] += count;
          } else {
            // If the country does not exist, create a new entry in the map
            combinedMap[country] = count;
          }
        });
      });

      return combinedMap;
    }
  },
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.btn-container .btn {
  margin-right: 2px;
}

.color-badge {
  display: inline-block;
  width: 15rem;
  height: 1rem;
}
</style>
